import dayjs from "dayjs";
import { Fragment } from "react";

import { getPeriod } from "@/lib/utils";
import { ForecastData } from "@/types";
import "@/pages/index/TrendHistory.scss";

export const TrendHistory = ({ forecast }: { forecast: ForecastData }) => {
	const formatDate = (date) => {
		return dayjs(date).format("DD.MM.YYYY");
	};

	return (
		<div className="forecast-detail">
			<div className="row">
				<div className="col-6 col-lg-6">
					<table className="trend-table">
						<tbody>
							<tr>
								<th>Trend date</th>
								{forecast?.trend_info?.map((item, i) => (
									<Fragment key={i}>
										<th>
											Actual Trend {item.shift} {getPeriod(forecast.forecast.granularity)}
										</th>
										<th>
											Forecasted Trend {item.shift} {getPeriod(forecast.forecast.granularity)}
										</th>
									</Fragment>
								))}
							</tr>
							{forecast?.trend_dates?.map((date) => (
								<tr key={date}>
									<td>{formatDate(date)}</td>
									{forecast?.trend_info?.map((item, i) => (
										<Fragment key={i}>
											<td className={item.history[date]?.status}>
												{item.history[date]?.actual_trend || "-"}
											</td>
											<td className={item.history[date]?.status}>
												{item.history[date]?.forecasted_trend || "-"}
											</td>
										</Fragment>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="col-6 col-lg-6">
					{forecast.forecast.target === "MB-STE-0096-Mid" && (
						<p>
							The historical evaluation of the <strong>Moirai</strong> model (started to be used
							from <strong>12.11.2024</strong>) for <strong>Week 4</strong> and{" "}
							<strong>Week 6</strong> trend shows{" "}
							<strong>the weighted average precision and recall</strong>.
							<br />
							Metrics calculation period: <strong>04.12.2023 - 03.02.2025</strong>
							<br />
							Week 4: <strong>Precision = 92% Recall = 92%</strong>
							<br />
							Week 6: <strong>Precision = 88% Recall = 85%</strong>
							<br />
							<br />
							The historical evaluation of the <strong>NBeats</strong> model (was used from{" "}
							<strong>06.09.2022</strong> to <strong>06.11.2024</strong>) for{" "}
							<strong>Week 4</strong> and <strong>Week 6</strong> trend shows{" "}
							<strong>the weighted average precision and recall</strong>.
							<br />
							Metrics calculation period: <strong>06.09.2022 - 06.11.2024</strong>
							<br />
							Week 4: <strong>Precision = 52% Recall = 54%</strong>
							<br />
							Week 6: <strong>Precision = 53% Recall = 56%</strong>
							<br />
						</p>
					)}
					{forecast.forecast.target === "BDSV-E2-8" && (
						<p>
							The historical evaluation of the <strong>Moirai</strong> model (started to be used
							from <strong>12.11.2024</strong>) for <strong>Month 1</strong> and{" "}
							<strong>Month 2</strong> trend shows{" "}
							<strong>the weighted average precision and recall</strong>.
							<br />
							Metrics calculation period: <strong>01.02.2020 - 01.02.2025</strong>
							<br />
							Month 1: <strong>Precision = 83% Recall = 80%</strong>
							<br />
							Month 2: <strong>Precision = 86% Recall = 83%</strong>
						</p>
					)}
				</div>
				<div>
					<div className="notes">
						<p>
							<span className="good">green rows</span> - actual and forecasted trends match
							<br />
							<span className="warning">yellow rows</span> - actual and forecasted trends mismatch,
							but with nearby values (UP and NO, DOWN and NO)
							<br />
							<span className="bad">red rows</span> - actual and forecasted trends mismatch (UP and
							DOWN)
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
