import dayjs from "dayjs";
import { Fragment } from "react";

import { getGranularity, getPeriod } from "@/lib/utils";
import { Card } from "@/pages/index/Card";
import { ForecastData } from "@/types";
import "@/pages/index/ForecastDetail.scss";

export const ForecastDetail = ({
	forecast,
	showParams,
}: {
	forecast: ForecastData;
	showParams: boolean;
}) => {
	const formatDate = (date) => {
		return dayjs(date).format("DD.MM.YYYY");
	};
	const formatSourceName = (value: string) => {
		return value.replace("_", " ");
	};
	const formatCurrency = (number) => {
		if (number) {
			return number.toFixed(2);
		}
		return number;
	};
	const formatChanges = (number) => {
		if (number) {
			return Math.round(number);
		}
		return number;
	};

	return (
		<div className="forecast-detail">
			<div className="row">
				<div className="col">
					<div className="row">
						<div className="col-12 col-lg-6">
							{/*<p>Volatility for last month: {forecast.volatility}</p>*/}
							<table className="data-table">
								<tbody>
									<tr>
										<th colSpan={3} className="capital">
											{getPeriod(forecast.forecast.granularity)}
										</th>
										<th>Preis ($/Tonne)</th>
										<th>ATR</th>
										<th colSpan={2}>Margin</th>
										<th className="empty"></th>
									</tr>
									<tr>
										<th>KW</th>
										<th>Start</th>
										<th>End</th>
										<th>{forecast.forecast.target}</th>
										<th>Changes, USD</th>
										<th>Up</th>
										<th>Low</th>
										<th>Trend Label</th>
									</tr>
									{forecast?.table?.map((item, i) => (
										<tr className={item.class} key={i}>
											<td>{item.week}</td>
											<td>{formatDate(item.start)}</td>
											<td>{formatDate(item.end)}</td>
											<td>{formatCurrency(item.price)}</td>
											<td>{formatChanges(item.changes)}</td>
											<td>{item.up}</td>
											<td>{item.low}</td>
											<td>{item.trend}</td>
										</tr>
									))}
								</tbody>
							</table>
							<div className="notes">
								<p>
									<span className="green">green rows</span> - history of actuals prices
									<br />
									<span className="yellow">yellow row</span> - the last observed actual price
									<br />
									<span className="blue">blue row</span> - forecasted prices
								</p>
								<p>
									<span className="legend-title">Changes</span> - predicted price chage relative to
									last observed date
									<br />
									<span className="legend-title">Up/Low Margin</span> - margins levels calculated
									reletivly to forecast date (last observed date)
									<br />
									<span className="legend-title">Trend Label</span> - calculated for some periods
									ahead
								</p>
							</div>
						</div>
						<div className="col-12 col-lg-6">
							<div className="image">
								<div dangerouslySetInnerHTML={{ __html: forecast.plot }} />
							</div>
							<div className="notes">
								<p>
									{forecast?.trend_info?.map((item, i) => (
										<Fragment key={i}>
											{item.bound_lower && (
												<>
													The forecasted price for{" "}
													<strong className="capital">
														{getPeriod(forecast.forecast.granularity)} {item.shift}
													</strong>{" "}
													is expected to be <strong>{item.price}</strong> (
													<strong>{item.trend}</strong> trend), with a{" "}
													<strong>{item.confidence}% confidence interval</strong> of{" "}
													<strong>
														{item.bound_lower} to {item.bound_upper}
													</strong>
													.
													<br />
												</>
											)}
										</Fragment>
									))}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="col-auto">
					{showParams && (
						<div className="params-block">
							<Card header={"Forecast Parameters"}>
								<p>
									<strong>Target:</strong> {forecast.title}
								</p>
								<p>
									<strong>Granularity:</strong> {getGranularity(forecast.forecast.granularity)}
								</p>
								<p>
									<strong>Horizont:</strong> {forecast.forecast.horizont}{" "}
									{getPeriod(forecast.forecast.granularity)}s
									<br />
									{formatDate(forecast.date_horizon_start)} -&nbsp;
									{formatDate(forecast.date_horizon_end)}
								</p>
								<p>
									<strong>Safety Margin:</strong> $&nbsp;20
								</p>
								<p>
									<strong>Data</strong>
								</p>
								<table>
									<tbody>
										{forecast?.source_states?.map((item, i) => (
											<Fragment key={i}>
												<tr>
													<td>{formatSourceName(item.source)}</td>
													<td>{formatDate(item.date)}</td>
												</tr>
											</Fragment>
										))}
									</tbody>
								</table>
								<br />
								<p>
									<strong>Model</strong>
								</p>
								<table>
									<tbody>
										<tr>
											<td>
												{forecast?.forecast?.ensemble_models?.length > 0 ? "Ensemble" : "Model"}{" "}
												version
											</td>
											<td>{formatDate(forecast?.forecast?.model?.version)}</td>
										</tr>
										{forecast?.forecast?.ensemble_models?.map((item, i) => (
											<Fragment key={i}>
												<tr>
													<td>|</td>
													<td></td>
												</tr>
												<tr>
													<td>|_ model_{i + 1}</td>
													<td>{formatDate(item.version)}</td>
												</tr>
											</Fragment>
										))}
									</tbody>
								</table>
							</Card>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
